<template>

  <div>
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="userRole"
      :unit-options="userUnit"
      :user-data="userData"
      @reset-data="resetUserData"
      @refetch-data="getUsers"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="getUsers"
            />
            <label>entries</label>
          </b-col>

          <b-col
            cols="12"
            md="2"
          >
            <v-select
              v-model="roleFilter"
              :options="userRole"
              class="w-100"
              label="group_name"
              placeholder="User Role"
              @input="getUsers()"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <v-select
              v-model="statusFilter"
              :options="statusOptions"
              class="w-100"
              placeholder="Status"
              @input="getUsers()"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="5"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-input-group>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block"
                  placeholder="Search..."
                  @keyup.enter="getUsers"
                />
                <b-input-group-append v-if="searchQuery !== ''">
                  <b-button
                    variant="outline-secondary"
                    @click="clearSearch"
                  >
                    <feather-icon
                      icon="XIcon"
                    />
                  </b-button>
                </b-input-group-append>
                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    @click="getUsers"
                  >
                    <feather-icon
                      icon="SearchIcon"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>

              <b-button
                variant="primary"
                class="ml-1"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">Add User</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="userList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        @sort-changed="getUsers"
      >

        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.image"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullname }}
            </b-link>
            <small class="text-muted">@{{ data.item.username }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ statusOptions[data.item.status].label }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="editData(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="data.item.status === 0"
              @click="confirmDel(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Block</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-else
              @click="confirmAct(data.item)"
            >
              <feather-icon icon="CheckCircleIcon" />
              <span class="align-middle ml-50">Activate</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.total }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="dataMeta.total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="onPageChange"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useUsersList from './useUsersList'
import UserListAddNew from './UserListAddNew.vue'

// Notification

export default {
  components: {
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {
    const toast = useToast()
    const isAddNewUserSidebarActive = ref(false)

    const statusOptions = [
      { label: 'Active', value: 0 },
      { label: 'Inactive', value: 1 },
      { label: 'All', value: 'all' },
    ]

    // Table Handlers
    const tableColumns = [
      { key: 'fullname', label: 'user', sortable: true },
      { key: 'email', sortable: true },
      { key: 'role', sortable: true },
      { key: 'unit', sortable: true },
      { key: 'status', sortable: true },
      { key: 'actions' },
    ]

    const perPage = ref(25)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('fullname')
    const isSortDirDesc = ref(false)
    const roleFilter = ref(null)
    const statusFilter = ref({ label: 'All', value: 'all' })

    const {
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
    } = useUsersList()

    return {
      toast,
      // Sidebar
      isAddNewUserSidebarActive,

      tableColumns,
      perPage,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      statusOptions,

      // Extra Filters
      roleFilter,
      statusFilter,
    }
  },
  data() {
    return {
      userRole: [],
      userUnit: [],
      userList: [],
      dataMeta: '',
      userData: {},
    }
  },
  created() {
    this.getUsers()
  },
  methods: {
    onPageChange(params) {
      window.scrollTo(0, 0)
      this.currentPage = params
      this.getUsers()
    },
    getUsers() {
      document.getElementById('loading-bg').style.display = 'block'

      const params = {
        length: this.perPage,
        filters: [],
      }
      if (this.searchQuery) {
        params.keyword = this.searchQuery
      }
      if (this.sortBy) {
        params.sort_by = [{
          column: this.sortBy,
          asc_desc: (this.isSortDirDesc) ? 'desc' : 'asc',
        }]
      }
      if (this.roleFilter) {
        params.filters.push({
          column: 'group_id',
          value: this.roleFilter.group_id,
        })
      }
      if (this.statusFilter && this.statusFilter.value !== 'all') {
        params.filters.push({
          column: 'block',
          value: this.statusFilter.value,
        })
      }
      let target = '/officer'
      if (this.currentPage !== 1) {
        target = `/officer?page=${this.currentPage}`
      }
      if (this.userRole.length === 0) {
        this.$http.post('ref/ug', true, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          },
        })
          .then(resp => {
            if (resp.status === 200) {
              this.userRole = resp.data.group
              this.userUnit = resp.data.unit
            }
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching role list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
      this.$http.post(target, params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            document.getElementById('loading-bg').style.display = 'none'

            this.userList = resp.data.data
            this.dataMeta = resp.data.meta
          }
        })
        .catch(() => {
          document.getElementById('loading-bg').style.display = 'none'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching users list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    editData(data) {
      this.userData = data
      this.isAddNewUserSidebarActive = true
    },
    clearSearch() {
      this.searchQuery = ''
      this.getUsers()
    },
    resetFaqData() {
      this.userData = {}
    },
    confirmDel(data) {
      this.$swal({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, block User!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$http.post('/block', { user_id: data.id }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            },
          })
            .then(resp => {
              if (resp.status === 200) {
                this.$swal({
                  icon: 'success',
                  title: 'Blocked!',
                  text: 'User has been blocked.',
                  showConfirmButton: false,
                })
                setTimeout(() => {
                  this.getUsers()
                  this.$swal.close()
                }, 1000)
              }
            })
            .catch(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: 'Error Blocking User',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    confirmAct(data) {
      this.$swal({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, activate User!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$http.post('/activate', { user_id: data.id }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            },
          })
            .then(resp => {
              if (resp.status === 200) {
                this.$swal({
                  icon: 'success',
                  title: 'Activated!',
                  text: 'User has been activated.',
                  showConfirmButton: false,
                })
                setTimeout(() => {
                  this.getUsers()
                  this.$swal.close()
                }, 1000)
              }
            })
            .catch(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: 'Error activating User',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    resetUserData() {
      this.userData = {}
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
