var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-user-sidebar","visible":_vm.isAddNewUserSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.resetForm,"change":function (val) { return _vm.$emit('update:is-add-new-user-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s((!_vm.userData.id) ? 'Add New' : 'Edit')+" User ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),(_vm.alert)?[_c('b-alert',{staticClass:"m-1",attrs:{"variant":_vm.alertDetail.variant,"show":""}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" "+_vm._s(_vm.alertDetail.title)+" ")]),(_vm.alertDetail.variant === 'danger')?_c('div',{staticClass:"alert-body"},[_c('ul',{staticClass:"pl-2"},_vm._l((_vm.alertDetail.text),function(item,index){return _c('li',{key:index},[_vm._l((item),function(txt){return [_vm._v(" "+_vm._s(txt)+" ")]})],2)}),0)]):_c('div',{staticClass:"alert-body"},[_vm._v(" "+_vm._s(_vm.alertDetail.text)+" ")])])]:_vm._e(),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.registerUser)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('validation-provider',{attrs:{"name":"Full Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Full Name","label-for":"full-name"}},[_c('b-form-input',{attrs:{"id":"full-name","state":errors.length > 0 ? false : null,"autofocus":"","trim":"","placeholder":"Full Name"},model:{value:(_vm.userData.fullname),callback:function ($$v) {_vm.$set(_vm.userData, "fullname", $$v)},expression:"userData.fullname"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Username","rules":"required|alpha-num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Username","label-for":"username"}},[_c('b-form-input',{attrs:{"id":"username","state":errors.length > 0 ? false : null,"trim":"","placeholder":"Userame"},model:{value:(_vm.userData.username),callback:function ($$v) {_vm.$set(_vm.userData, "username", $$v)},expression:"userData.username"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Email","rules":(!_vm.userData.id) ? 'required|email' : 'email'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null,"trim":"","placeholder":"Email"},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Phone Number","rules":(!_vm.userData.id) ? 'required|numeric' : 'numeric'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Phone Number","label-for":"phonenumber"}},[_c('b-form-input',{attrs:{"id":"phonenumber","state":errors.length > 0 ? false : null,"type":"number","trim":"","placeholder":"Phonenumber"},model:{value:(_vm.userData.phonenumber),callback:function ($$v) {_vm.$set(_vm.userData, "phonenumber", $$v)},expression:"userData.phonenumber"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"NIK","rules":(!_vm.userData.id) ? 'required|numeric' : 'numeric'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"NIK","label-for":"nik"}},[_c('b-form-input',{attrs:{"id":"nik","state":errors.length > 0 ? false : null,"type":"number","trim":"","placeholder":"NIK"},model:{value:(_vm.userData.nik),callback:function ($$v) {_vm.$set(_vm.userData, "nik", $$v)},expression:"userData.nik"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Password","rules":(!_vm.userData.id) ? 'required|confirmed:confirm|password' : 'confirmed:confirm|password'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Password","label-for":"password"}},[_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.userData.password),callback:function ($$v) {_vm.$set(_vm.userData, "password", $$v)},expression:"userData.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('span',{staticClass:"small"},[_vm._v("Min. 8 digits, 1 number, 1 Uppercase, 1 Lowercase, 1 special character.")]),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Re-type Password","rules":(!_vm.userData.id) ? 'required|password' : 'password',"vid":"confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Password Confirmation","label-for":"password_confirmation"}},[_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{attrs:{"id":"password_conf","type":_vm.passwordFieldTypeRetype,"state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.userData.password_confirmation),callback:function ($$v) {_vm.$set(_vm.userData, "password_confirmation", $$v)},expression:"userData.password_confirmation"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconRetype},on:{"click":_vm.togglePasswordRetype}})],1)],1),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"User Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"User Role","label-for":"user-role","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"options":_vm.roleOptions,"reduce":function (val) { return val.group_id; },"clearable":false,"label":"group_name","input-id":"user-role"},model:{value:(_vm.userData.group_id),callback:function ($$v) {_vm.$set(_vm.userData, "group_id", $$v)},expression:"userData.group_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Unit Kerja","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Unit Kerja","label-for":"user-unit","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"options":_vm.unitOptions,"reduce":function (val) { return val.unit_id; },"clearable":false,"label":"unit_name","input-id":"user-unit"},model:{value:(_vm.userData.unit_id),callback:function ($$v) {_vm.$set(_vm.userData, "unit_id", $$v)},expression:"userData.unit_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-50",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Save ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Cancel ")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }