import { ref } from '@vue/composition-api'

export default function useUsersList() {
  const refUserListTable = ref(null)

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'Officer') return 'success'
    if (role === 'Head Admin') return 'info'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'Officer') return 'Edit2Icon'
    if (role === 'Head Admin') return 'ServerIcon'
    return 'DatabaseIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 0) return 'success'
    if (status === 1) return 'secondary'
    return 'primary'
  }

  return {
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
  }
}
