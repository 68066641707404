<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ (!userData.id) ? 'Add New' : 'Edit' }} User
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <template v-if="alert">
        <b-alert
          :variant="alertDetail.variant"
          class="m-1"
          show
        >
          <h4 class="alert-heading">
            {{ alertDetail.title }}
          </h4>
          <div
            v-if="alertDetail.variant === 'danger'"
            class="alert-body"
          >
            <ul class="pl-2">
              <li
                v-for="(item, index) in alertDetail.text"
                :key="index"
              >
                <template v-for="txt in item">
                  {{ txt }}
                </template>
              </li>
            </ul>
          </div>
          <div
            v-else
            class="alert-body"
          >
            {{ alertDetail.text }}
          </div>
        </b-alert>
      </template>

      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(registerUser)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="{ errors }"
            name="Full Name"
            rules="required"
          >
            <b-form-group
              label="Full Name"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="userData.fullname"
                :state="errors.length > 0 ? false : null"
                autofocus
                trim
                placeholder="Full Name"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="{ errors }"
            name="Username"
            rules="required|alpha-num"
          >
            <b-form-group
              label="Username"
              label-for="username"
            >
              <b-form-input
                id="username"
                v-model="userData.username"
                :state="errors.length > 0 ? false : null"
                trim
                placeholder="Userame"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="{ errors }"
            name="Email"
            :rules="(!userData.id) ? 'required|email' : 'email'"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.email"
                :state="errors.length > 0 ? false : null"
                trim
                placeholder="Email"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="Phone Number"
            :rules="(!userData.id) ? 'required|numeric' : 'numeric'"
          >
            <b-form-group
              label="Phone Number"
              label-for="phonenumber"
            >
              <b-form-input
                id="phonenumber"
                v-model="userData.phonenumber"
                :state="errors.length > 0 ? false : null"
                type="number"
                trim
                placeholder="Phonenumber"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="NIK"
            :rules="(!userData.id) ? 'required|numeric' : 'numeric'"
          >
            <b-form-group
              label="NIK"
              label-for="nik"
            >
              <b-form-input
                id="nik"
                v-model="userData.nik"
                :state="errors.length > 0 ? false : null"
                type="number"
                trim
                placeholder="NIK"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Password -->
          <validation-provider
            #default="{ errors }"
            name="Password"
            :rules="(!userData.id) ? 'required|confirmed:confirm|password' : 'confirmed:confirm|password'"
          >
            <b-form-group
              label="Password"
              label-for="password"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="password"
                  v-model="userData.password"
                  :type="passwordFieldType"
                  :state="errors.length > 0 ? false : null"
                  trim
                />

                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <span class="small">Min. 8 digits, 1 number, 1 Uppercase, 1 Lowercase, 1 special character.</span>

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Password Confirm -->
          <validation-provider
            #default="{ errors }"
            name="Re-type Password"
            :rules="(!userData.id) ? 'required|password' : 'password'"
            vid="confirm"
          >
            <b-form-group
              label="Password Confirmation"
              label-for="password_confirmation"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="password_conf"
                  v-model="userData.password_confirmation"
                  :type="passwordFieldTypeRetype"
                  :state="errors.length > 0 ? false : null"
                  trim
                />

                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIconRetype"
                    @click="togglePasswordRetype"
                  />
                </b-input-group-append>
              </b-input-group>

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- User Role -->
          <validation-provider
            #default="{ errors }"
            name="User Role"
            rules="required"
          >
            <b-form-group
              label="User Role"
              label-for="user-role"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                v-model="userData.group_id"
                :options="roleOptions"
                :reduce="val => val.group_id"
                :clearable="false"
                label="group_name"
                input-id="user-role"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- User Unit -->
          <validation-provider
            #default="{ errors }"
            name="Unit Kerja"
            rules="required"
          >
            <b-form-group
              label="Unit Kerja"
              label-for="user-unit"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                v-model="userData.unit_id"
                :options="unitOptions"
                :reduce="val => val.unit_id"
                :clearable="false"
                label="unit_name"
                input-id="user-unit"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-50"
              type="submit"
            >
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BAlert, BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
  BInputGroupAppend, BInputGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, alphaNum, password, email, numeric,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroupAppend,
    BInputGroup,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    unitOptions: {
      type: Array,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      password,
      numeric,
      email,
      passwordFieldTypeRetype: 'password',
      alert: false,
      alertDetail: {
        variant: 'info',
        title: 'Info',
        text: '',
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  setup() {
    const toast = useToast()

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      toast,

      refFormObserver,
      getValidationState,
    }
  },
  methods: {
    resetForm() {
      this.$emit('reset-data')
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    registerUser() {
      document.getElementById('loading-bg').style.display = 'block'
      const params = {
        fullname: this.userData.fullname,
        username: this.userData.username,
        group_id: this.userData.group_id,
        unit_id: this.userData.unit_id,
      }

      if (this.userData.email) {
        params.email = this.userData.email
      }
      if (this.userData.phonenumber) {
        params.phonenumber = this.userData.phonenumber
      }
      if (this.userData.nik) {
        params.nik = this.userData.nik
      }
      if (this.userData.password) {
        params.password = this.userData.password
        params.password_confirmation = this.userData.password_confirmation
      }

      let targetUrl = '/register'
      if (this.userData.id) {
        params.user_id = this.userData.id
        targetUrl = '/profile'
      }
      this.$http.post(targetUrl, params, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            text: (error.response.data.errors !== '') ? error.response.data.errors : error,
          }
          this.toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .then(resp => {
          document.getElementById('loading-bg').style.display = 'none'
          if (resp.status === 200 && resp.data.success === true) {
            this.alert = false
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })

            this.$emit('refetch-data')
            this.$emit('update:is-add-new-user-sidebar-active', false)
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
